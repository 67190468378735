@import url('./fonts.css');
.headline.title {
  display: none !important;
}
p.smalltext.postInfoText {
  display: none !important;
}
.module.text .bodytext.contentText:nth-child(2) {
  display: none !important;
}
.module.text .bodytext.contentText:nth-child(3) {
  background: white !important;
}
.module.text .bodytext.contentText {
  margin-bottom: 0px !important;
}
.shareWrapper, .commentsAreaWrapper, .rssicon {
  display: none !important;
}
div.row.singlePost {
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.cd-main-container {
  display: flex;
  flex-direction: column;
  font-family: var(--cd-font);
  font-size: 16px;
  min-height: 100vh;
  align-items: center;
}
.cd-padded-container {
  width: 100% !important;
  line-height: 1.5;
  margin-bottom: 0px !important;

  /*
    !important is here because on line 171 in [[...eventTitle]].tsx in the following PR, the backgroundColor was not white,
    so without !important old events would still have that color despite it supposed to be white. We would have to redeploy all mono-blogs to fix this.
    PR: https://github.com/ChurchDesk/portal-widget/pull/570/files#diff-681ae99a3323162d864d79bf1b1b26aa1f0aa6a3542824b1e320262a279d96c4
  */
  background-color: white !important;
}
.cd-padded-container-child {
  max-width: 625px;
  margin: auto;
}
.cd-event-blog-image-logo-container {
  display: 'flex';
  flex-direction: column;
  align-items: center;
}
.cd-logo {
  height: 64px;
}
.cd-event-blog-logo {
  height: 48px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  display: block;
}
.cd-event-blog-image {
  margin-top: 40px;
}
.cd-image-with-copyright {
  position: relative;
  height: fit-content;
  border-bottom-right-radius: var(--cd-border-radius);
}
.cd-image-credit {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: rgb(64 64 64 / 50%);
  color: #ebebeb;
  border-top-left-radius: 4px;
  font-size: 12px;
  border-bottom-right-radius: var(--cd-border-radius)!important;
}
.cd-image-container {
  width: 100%;
  display: block;
  border-radius:var(--cd-border-radius);
}
.cd-event-blog-title {
  color: #595959;
  padding-top: 0px;
  margin: 0px;
  font-size: 38px;
  font-weight: 600 !important;
  line-height: 42px;
}
.cd-event-detail-container {
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;
  margin-top: 40px;
  padding: 16px 24px 40px 40px;
  border-radius: var(--cd-border-radius);
  margin-bottom: 40px;
}
.cd-event-detail-items-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.cd-detail-item-col {
  width: 50%;
}
.cd-detail-item-container {
  display: flex;
  column-gap: 8px;
  align-items: flex-start;
  padding-top: 24px;
  padding-right: 16px;
  font-size: 20px !important;
  color: #595959;
}
.cd-detail-item-text {
  margin-bottom: 0px;
  font-weight: 400 !important;
}
.cd-detail-item-label{
  padding-block-end: 0px;
}

.cd-blog-description .embed-container iframe {
  /* Ensures that ChurchDesk forms are responsive indside the descrition box. */
  min-width: auto !important;
}
.cd-details-icon {
  width: 20px !important;
  height: 20px !important;
  font-size: 18px !important;
  padding-top: 2px !important;
}
.cd-section-title{
  font-size: 20px;
}
.cd-inentions-title{
  padding: 0 16px 16px 16px;
}
.cd-intentions{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0 16px 0 16px;
}
.cd-event-description {
  margin: 0px 0px 40px;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
}
.cd-event-form-container {
  width: 625px;
}
.cd-event-form {
  padding: 0 16px 0 16px;
}
.cd-description {
  padding: 0 16px 0 16px;
  hyphens: none;
}
.cd-description p {
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  max-width: 625px !important;
  width: 100% !important;
  word-wrap: break-word !important; 
  overflow-wrap: break-word !important; 
  white-space: normal !important; 
}
.cd-description img {
  max-width: 625px !important;
  width: auto;
  height: auto;
}
.cd-description strong {
  font-weight: 600 !important;
  max-width: 625px !important ;
}
.cd-description figure {
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  max-width: 625px !important ;
}
.cd-description a {
  text-decoration: underline !important;
  max-width: 625px !important ;
  color: var(--cd-readable-highlighted-color);
}
.cd-description .text-left {
  text-align: left !important;
}
.cd-description .text-right {
  text-align: right !important;
}
.cd-description .text-center {
  text-align: center !important;
}
.cd-description .text-justify {
  text-align: justify !important;
}
.cd-description h1,
.cd-description h2,
.cd-description h3,
.cd-description h4,
.cd-description h5,
.cd-description h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  max-width: 625px !important;
  width: 100% !important;
  color: black !important;
}
.cd-description h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
.cd-description h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
.cd-description h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.cd-description h4 {
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.cd-description h5 {
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
.cd-description h6 {
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}

.cd-description blockquote {
  border-left: 1px solid rgba(0, 0, 0, .1);
  padding-left: 24px;
  margin: 0;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, .6);
  font-style: italic;
}

.cd-suggested-widget-container {
  display: var(--cd-display-suggestion);
  flex-direction: column;
  width: 625px;
  justify-content: center;
}
.cd-suggestion-part-text {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
  color: var(--cd-contrast-color);
  text-align: center;
  justify-content: center;
  padding-left:0px;
  padding-right:0px
}
.cd-blog-suggestion {
  margin-top: 40px;
}
.cd-loading {
  display: block;
  position: absolute;
  left: 50%;
  height: 24px;
}
.cd-blog-title-color {
  color: var(--cd-readable-highlighted-color);
}
.cd-event-title-color {
  color: var(--cd-readable-highlighted-color);
}
.cd-blog-top-items {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  padding-left: 0px;
  padding-right: 0px;
}
.cd-blog-title-container {
  margin-top: 0px;
}
.cd-blog-category-style {
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: bold;
}

.cd-blog-description {
  margin: 40px 0px ;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.cd-blog-author-date {
  color: black;
}
.cd-description-title {
  color: black;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
  margin-block-start: 0px;
}

.cd-no-suggestion-part {
  margin: 20px;
}
.cd-suggestion-ribon {
  padding: 56px 0px;
  text-align: center;
  justify-content: center;
  background-color: var(--cd-primary-color);
  width: 625px;
  border-radius: var(--cd-border-radius);
}
.cd-event-blog-title {
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .cd-event-detail-container,
  .cd-blog-top-items,
  .cd-description,
  .cd-description-title,
  .cd-suggestion-part-text ,
  .cd-event-form {
    padding-left: 16px;
    padding-right: 16px;
    overflow-wrap: break-word !important;
  }
  .cd-event-blog-title {
    font-size: 26px;
    padding: 0 16px 0 16px;
  }
  .cd-detail-item-col, .cd-suggested-widget-container,.cd-suggestion-ribon, .cd-event-form-container {
    width: 100%;
  }
}
.cd-description span > *:first-child{
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.cd-description span > *:last-child{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.cd-main-container div:has(> iframe:only-child){
    width: 625px !important;
    margin-bottom:40px ;
    max-width: 100% !important;
  }
.cd-main-container div:has(> iframe:only-child) > iframe{
    border-radius: var(--cd-border-radius) !important;
  }
