:root, :host {
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Solid';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Regular';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Light';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Thin';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone';
  --fa-font-sharp-solid: normal 900 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-regular: normal 400 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-light: normal 300 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-thin: normal 100 1em/1 'Font Awesome 6 Sharp';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box; }

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -.125em; }
  .svg-inline--fa.fa-2xs {
    vertical-align: 0.1em; }
  .svg-inline--fa.fa-xs {
    vertical-align: 0em; }
  .svg-inline--fa.fa-sm {
    vertical-align: -0.07143em; }
  .svg-inline--fa.fa-lg {
    vertical-align: -0.2em; }
  .svg-inline--fa.fa-xl {
    vertical-align: -0.25em; }
  .svg-inline--fa.fa-2xl {
    vertical-align: -0.3125em; }
  .svg-inline--fa.fa-pull-left {
    margin-right: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-pull-right {
    margin-left: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-li {
    width: var(--fa-li-width, 2em);
    top: 0.25em; }
  .svg-inline--fa.fa-fw {
    width: var(--fa-fw-width, 1.25em); }

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.fa-layers-text, .fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center; }

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em; }
  .fa-layers svg.svg-inline--fa {
    transform-origin: center center; }

.fa-layers-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center; }

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  transform: scale(var(--fa-counter-scale, 0.25));
  transform-origin: top right; }

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: bottom right; }

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: bottom left; }

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: top right; }

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: top left; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  transform: rotate(90deg); }

.fa-rotate-180 {
  transform: rotate(180deg); }

.fa-rotate-270 {
  transform: rotate(270deg); }

.fa-flip-horizontal {
  transform: scale(-1, 1); }

.fa-flip-vertical {
  transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none)); }

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto); }

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em; }

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black; }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff); }

#root {
  display: flex;
}

#__next {
  height: 100%;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* new 23 fonts tp match the variable font of widget   */
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-300-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-300-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-300-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-300-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-400-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-500-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-600-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-700-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-800-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-800-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-cyrillic-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-cyrillic-400-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-cyrillic-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-greek-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-latin-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-latin-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-vietnamese-400-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-300-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-300-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-400-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-500-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-600-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-700-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-800-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-300-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-ext-300-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-greek-ext-800-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/inter/files/inter-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-greek-300-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-300-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-300-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-300-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-400-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-500-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-600-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-700-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-800-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-800-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}
/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-400-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-400-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-500-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-500-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-600-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-600-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-700-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-700-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-800-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-800-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-800-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-900-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-900-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-900-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-900-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-300-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-300-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-300-normal.woff)
      format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-300-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-300-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-300-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-400-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-400-normal.woff)
      format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-400-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-400-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-500-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-500-normal.woff)
      format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-500-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-500-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-600-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-600-normal.woff)
      format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-600-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-600-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-700-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-700-normal.woff)
      format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-700-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-700-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-800-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-800-normal.woff)
      format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-800-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-800-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-800-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/asap/files/asap-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-200-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-200-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-200-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-200-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-300-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-300-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-300-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-400-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-500-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-600-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-700-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-800-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}

/* New fonts */
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-100-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-100-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-100-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-100-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-100-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-100-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-100-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-100-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-100-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-100-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-200-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-200-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-200-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-200-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-200-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-300-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-300-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-800-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-800-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-900-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-900-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-900-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-900-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-900-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-100-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-100-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-100-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-100-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-100-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-100-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-100-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-100-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-200-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-200-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-900-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-900-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-300-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-300-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-300-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-400-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-500-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-600-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-700-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-800-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-800-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-800-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-900-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-900-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-900-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-300-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-400-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-500-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-600-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-700-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-800-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-hebrew-900-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-900-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-900-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-100-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-100-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-100-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-100-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-100-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-100-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-200-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-200-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-200-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-900-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-900-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-900-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-100-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-100-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-100-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-100-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-200-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-200-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-200-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-200-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-200-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-200-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-300-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-300-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-800-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-800-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-900-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-900-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-900-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-900-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-200-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-200-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-900-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-900-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-100-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-100-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-100-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-100-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-100-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-100-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-200-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-200-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-200-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-900-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-900-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-900-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-100-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-100-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-100-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-100-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-400-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-hebrew-400-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-400-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-500-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-hebrew-500-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-500-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-600-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-hebrew-600-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-600-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-700-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-hebrew-700-italic.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-700-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-hebrew-400-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-hebrew-500-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-hebrew-600-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-hebrew-700-normal.woff) format('woff');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-400-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-400-italic.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-400-italic.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-400-italic.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-400-italic.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-400-italic.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-500-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-500-italic.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-500-italic.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-500-italic.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-500-italic.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-500-italic.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-600-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-600-italic.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-600-italic.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-600-italic.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-600-italic.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-600-italic.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-700-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-700-italic.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-700-italic.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-700-italic.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-700-italic.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-700-italic.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-800-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-800-italic.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-800-italic.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-800-italic.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-800-italic.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-800-italic.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-400-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-400-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-400-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-400-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-500-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-500-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-500-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-500-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-600-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-600-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-600-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-600-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-700-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-700-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-700-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-700-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-800-normal.woff)
      format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-800-normal.woff)
      format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-800-normal.woff)
      format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-800-normal.woff)
      format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/karla/files/karla-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-100-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-100-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/sora/files/sora-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-100-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-100-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-100-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-100-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-200-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-200-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-300-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-300-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-400-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-400-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-500-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-500-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-600-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-600-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-700-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-700-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-800-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-800-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-900-italic.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-900-italic.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-100-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-100-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-200-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-200-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-300-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-300-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-400-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-400-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-500-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-500-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-600-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-600-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-700-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-700-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-800-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-800-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-900-normal.woff2) format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301,
    U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-900-normal.woff2)
      format('woff2'),
    url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/*
New fonts className
*/
.Open-Sans {
  font-family: 'Open Sans';
}
.Roboto_Flex {
  font-family: 'Roboto Flex';
}
.Space-Grotesk {
  font-family: 'Space Grotesk';
}
.Alegreya {
  font-family: 'Alegreya';
}
.Cinzel {
  font-family: 'Cinzel';
}
.Inter {
  font-family: 'Inter';
}
.Manrope {
  font-family: 'Manrope';
}
.Source_Sans_3 {
  font-family: 'Source Sans 3';
}
.Playfair_Display {
  font-family: 'Playfair Display';
}
.Archivo {
  font-family: 'Archivo';
}
.Asap {
  font-family: 'Asap';
}
.Montserrat {
  font-family: 'Montserrat';
}
/* New fonts */
.Raleway {
  font-family: 'Raleway';
}
.Rubik {
  font-family: 'Rubik';
}
.Epilogue {
  font-family: 'Epilogue';
}
.Oswald {
  font-family: 'Oswald';
}
.Nunito {
  font-family: 'Nunito';
}
.Work_Sans {
  font-family: 'Work Sans';
}
.Cabin {
  font-family: 'Cabin';
}
.Arimo {
  font-family: 'Arimo';
}
.EB_Garamond {
  font-family: 'EB Garamond';
}
.Faustina {
  font-family: 'Faustina';
}
.Karla {
  font-family: 'Karla';
}
.Lora {
  font-family: 'Lora';
}
.Sora {
  font-family: 'Sora';
}
.Urbanist {
  font-family: 'Urbanist';
}
/*
web safe fonts className
*/
.Helevetica {
  font-family: 'Helvetica';
}
.Arial {
  font-family: 'Arial';
}
.Arial_Black {
  font-family: 'Arial Black';
}
.Verdana {
  font-family: 'Verdana';
}
.Tahoma {
  font-family: 'Tahoma';
}
.Trebuchet_MS {
  font-family: 'Trebuchet MS';
}
.Impact {
  font-family: 'Impact';
}
.Times_New_Roman {
  font-family: 'Times New Roman';
}
.Georgia {
  font-family: 'Georgia';
}

.headline.title {
  display: none !important;
}
p.smalltext.postInfoText {
  display: none !important;
}
.module.text .bodytext.contentText:nth-child(2) {
  display: none !important;
}
.module.text .bodytext.contentText:nth-child(3) {
  background: white !important;
}
.module.text .bodytext.contentText {
  margin-bottom: 0px !important;
}
.shareWrapper, .commentsAreaWrapper, .rssicon {
  display: none !important;
}
div.row.singlePost {
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.cd-main-container {
  display: flex;
  flex-direction: column;
  font-family: var(--cd-font);
  font-size: 16px;
  min-height: 100vh;
  align-items: center;
}
.cd-padded-container {
  width: 100% !important;
  line-height: 1.5;
  margin-bottom: 0px !important;

  /*
    !important is here because on line 171 in [[...eventTitle]].tsx in the following PR, the backgroundColor was not white,
    so without !important old events would still have that color despite it supposed to be white. We would have to redeploy all mono-blogs to fix this.
    PR: https://github.com/ChurchDesk/portal-widget/pull/570/files#diff-681ae99a3323162d864d79bf1b1b26aa1f0aa6a3542824b1e320262a279d96c4
  */
  background-color: white !important;
}
.cd-padded-container-child {
  max-width: 625px;
  margin: auto;
}
.cd-event-blog-image-logo-container {
  display: 'flex';
  flex-direction: column;
  align-items: center;
}
.cd-logo {
  height: 64px;
}
.cd-event-blog-logo {
  height: 48px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  display: block;
}
.cd-event-blog-image {
  margin-top: 40px;
}
.cd-image-with-copyright {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom-right-radius: var(--cd-border-radius);
}
.cd-image-credit {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: rgb(64 64 64 / 50%);
  color: #ebebeb;
  border-top-left-radius: 4px;
  font-size: 12px;
  border-bottom-right-radius: var(--cd-border-radius)!important;
}
.cd-image-container {
  width: 100%;
  display: block;
  border-radius:var(--cd-border-radius);
}
.cd-event-blog-title {
  color: #595959;
  padding-top: 0px;
  margin: 0px;
  font-size: 38px;
  font-weight: 600 !important;
  line-height: 42px;
}
.cd-event-detail-container {
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;
  margin-top: 40px;
  padding: 16px 24px 40px 40px;
  border-radius: var(--cd-border-radius);
  margin-bottom: 40px;
}
.cd-event-detail-items-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.cd-detail-item-col {
  width: 50%;
}
.cd-detail-item-container {
  display: flex;
  -moz-column-gap: 8px;
       column-gap: 8px;
  align-items: flex-start;
  padding-top: 24px;
  padding-right: 16px;
  font-size: 20px !important;
  color: #595959;
}
.cd-detail-item-text {
  margin-bottom: 0px;
  font-weight: 400 !important;
}
.cd-detail-item-label{
  padding-block-end: 0px;
}

.cd-blog-description .embed-container iframe {
  /* Ensures that ChurchDesk forms are responsive indside the descrition box. */
  min-width: auto !important;
}
.cd-details-icon {
  width: 20px !important;
  height: 20px !important;
  font-size: 18px !important;
  padding-top: 2px !important;
}
.cd-section-title{
  font-size: 20px;
}
.cd-inentions-title{
  padding: 0 16px 16px 16px;
}
.cd-intentions{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0 16px 0 16px;
}
.cd-event-description {
  margin: 0px 0px 40px;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
}
.cd-event-form-container {
  width: 625px;
}
.cd-event-form {
  padding: 0 16px 0 16px;
}
.cd-description {
  padding: 0 16px 0 16px;
  -webkit-hyphens: none;
          hyphens: none;
}
.cd-description p {
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  max-width: 625px !important;
  width: 100% !important;
  word-wrap: break-word !important; 
  overflow-wrap: break-word !important; 
  white-space: normal !important; 
}
.cd-description img {
  max-width: 625px !important;
  width: auto;
  height: auto;
}
.cd-description strong {
  font-weight: 600 !important;
  max-width: 625px !important ;
}
.cd-description figure {
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  max-width: 625px !important ;
}
.cd-description a {
  text-decoration: underline !important;
  max-width: 625px !important ;
  color: var(--cd-readable-highlighted-color);
}
.cd-description .text-left {
  text-align: left !important;
}
.cd-description .text-right {
  text-align: right !important;
}
.cd-description .text-center {
  text-align: center !important;
}
.cd-description .text-justify {
  text-align: justify !important;
}
.cd-description h1,
.cd-description h2,
.cd-description h3,
.cd-description h4,
.cd-description h5,
.cd-description h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  max-width: 625px !important;
  width: 100% !important;
  color: black !important;
}
.cd-description h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
.cd-description h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
.cd-description h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.cd-description h4 {
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.cd-description h5 {
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
.cd-description h6 {
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}

.cd-description blockquote {
  border-left: 1px solid rgba(0, 0, 0, .1);
  padding-left: 24px;
  margin: 0;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, .6);
  font-style: italic;
}

.cd-suggested-widget-container {
  display: var(--cd-display-suggestion);
  flex-direction: column;
  width: 625px;
  justify-content: center;
}
.cd-suggestion-part-text {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
  color: var(--cd-contrast-color);
  text-align: center;
  justify-content: center;
  padding-left:0px;
  padding-right:0px
}
.cd-blog-suggestion {
  margin-top: 40px;
}
.cd-loading {
  display: block;
  position: absolute;
  left: 50%;
  height: 24px;
}
.cd-blog-title-color {
  color: var(--cd-readable-highlighted-color);
}
.cd-event-title-color {
  color: var(--cd-readable-highlighted-color);
}
.cd-blog-top-items {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  padding-left: 0px;
  padding-right: 0px;
}
.cd-blog-title-container {
  margin-top: 0px;
}
.cd-blog-category-style {
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: bold;
}

.cd-blog-description {
  margin: 40px 0px ;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.cd-blog-author-date {
  color: black;
}
.cd-description-title {
  color: black;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
  margin-block-start: 0px;
}

.cd-no-suggestion-part {
  margin: 20px;
}
.cd-suggestion-ribon {
  padding: 56px 0px;
  text-align: center;
  justify-content: center;
  background-color: var(--cd-primary-color);
  width: 625px;
  border-radius: var(--cd-border-radius);
}
.cd-event-blog-title {
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .cd-event-detail-container,
  .cd-blog-top-items,
  .cd-description,
  .cd-description-title,
  .cd-suggestion-part-text ,
  .cd-event-form {
    padding-left: 16px;
    padding-right: 16px;
    overflow-wrap: break-word !important;
  }
  .cd-event-blog-title {
    font-size: 26px;
    padding: 0 16px 0 16px;
  }
  .cd-detail-item-col, .cd-suggested-widget-container,.cd-suggestion-ribon, .cd-event-form-container {
    width: 100%;
  }
}
.cd-description span > *:first-child{
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.cd-description span > *:last-child{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.cd-main-container div:has(> iframe:only-child){
    width: 625px !important;
    margin-bottom:40px ;
    max-width: 100% !important;
  }
.cd-main-container div:has(> iframe:only-child) > iframe{
    border-radius: var(--cd-border-radius) !important;
  }

body {
  background-color: transparent !important;
  -webkit-hyphens: auto;
          hyphens: auto;
  margin: 0;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Alegreya_ae2571';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/b91bb2c3e151ee36-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Alegreya_ae2571';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/a7be84dc872cbcbd-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Alegreya_ae2571';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/bbddbdc23dfb46ad-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Alegreya_ae2571';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/5433acdb3cc6ab7c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Alegreya_ae2571';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/4d250567e428eddd-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Alegreya_ae2571';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/9f7016c74e6c21cd-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Alegreya_ae2571';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/575bcb56b500cc9b-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Alegreya_Fallback_ae2571';src: local("Times New Roman");ascent-override: 100.67%;descent-override: 34.18%;line-gap-override: 0.00%;size-adjust: 100.92%
}.__className_ae2571 {font-family: '__Alegreya_ae2571', '__Alegreya_Fallback_ae2571';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_eef930';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_eef930';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_eef930';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_eef930';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_eef930';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_eef930';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_eef930';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/a34f9d1faa5f3315-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_eef930';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_eef930 {font-family: '__Inter_eef930', '__Inter_Fallback_eef930';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_a0ce4e';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/88da59c891d37117-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_a0ce4e';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/1e377d93cbdd6b44-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_a0ce4e';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/36d711d427a06e51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_a0ce4e';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/f1f7cdf240180e28-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_a0ce4e';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/55fbdf033607a0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_a0ce4e';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/b6a6f0b43d027304-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_a0ce4e';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/9cf9c6e84ed13b5e-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_a0ce4e';src: local("Arial");ascent-override: 109.21%;descent-override: 42.66%;line-gap-override: 0.00%;size-adjust: 93.76%
}.__className_a0ce4e {font-family: '__Source_Sans_3_a0ce4e', '__Source_Sans_3_Fallback_a0ce4e';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Manrope_50e841';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/78187650dd6b50b3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Manrope_50e841';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/4529092560591ab4-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Manrope_50e841';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/b8222d26e20b2e06-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Manrope_50e841';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/008f2e8b4aae291f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Manrope_50e841';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/3c46462b57ac880e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Manrope_50e841';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/3534416bbfdcc9be-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Manrope_Fallback_50e841';src: local("Arial");ascent-override: 103.31%;descent-override: 29.07%;line-gap-override: 0.00%;size-adjust: 103.19%
}.__className_50e841 {font-family: '__Manrope_50e841', '__Manrope_Fallback_50e841';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Flex_762822';
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/6132b945e1bf1b6b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Flex_762822';
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/8fd61259cb20e8eb-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Roboto_Flex_762822';
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/ab7a1c07ef982496-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Flex_762822';
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/a8f3cee991756b5e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Flex_762822';
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/55cab9a7b1e584c7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Flex_762822';
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/b5c95a22b4347906-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Flex_Fallback_762822';src: local("Arial");ascent-override: 93.28%;descent-override: 24.55%;line-gap-override: 0.00%;size-adjust: 99.45%
}.__className_762822 {font-family: '__Roboto_Flex_762822', '__Roboto_Flex_Fallback_762822';font-style: normal
}

/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_be14c6';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/ae80e08d9fcae03a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_be14c6';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/4486f70b101e60d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_be14c6';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/3e57fe4abb1c4cae-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_be14c6';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/dcc209c0b1ab30af-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Playfair_Display_Fallback_be14c6';src: local("Times New Roman");ascent-override: 97.25%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 111.26%
}.__className_be14c6 {font-family: '__Playfair_Display_be14c6', '__Playfair_Display_Fallback_be14c6';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Archivo_3196f5';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/623a6513000dba8b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Archivo_3196f5';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/4627092dc219a0c7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Archivo_3196f5';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/b4f46b1ccc361ec4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Archivo_Fallback_3196f5';src: local("Arial");ascent-override: 88.96%;descent-override: 21.28%;line-gap-override: 0.00%;size-adjust: 98.70%
}.__className_3196f5 {font-family: '__Archivo_3196f5', '__Archivo_Fallback_3196f5';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Asap_fe0c89';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/909654df409d1120-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Asap_fe0c89';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/0ee1058bacc1dc2a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Asap_fe0c89';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/670ce9ea64abacc3-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Asap_Fallback_fe0c89';src: local("Arial");ascent-override: 94.20%;descent-override: 21.38%;line-gap-override: 0.00%;size-adjust: 99.15%
}.__className_fe0c89 {font-family: '__Asap_fe0c89', '__Asap_Fallback_fe0c89';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Space_Grotesk_ca24cd';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/e1aab0933260df4d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Space_Grotesk_ca24cd';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/b7387a63dd068245-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Space_Grotesk_ca24cd';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/7cba1811e3c25a15-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Space_Grotesk_Fallback_ca24cd';src: local("Arial");ascent-override: 89.71%;descent-override: 26.62%;line-gap-override: 0.00%;size-adjust: 109.69%
}.__className_ca24cd {font-family: '__Space_Grotesk_ca24cd', '__Space_Grotesk_Fallback_ca24cd';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/faac4ac11aa3d97b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Open_Sans_13c7d6';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/3d9ea938b6afa941-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Open_Sans_Fallback_13c7d6';src: local("Arial");ascent-override: 101.65%;descent-override: 27.86%;line-gap-override: 0.00%;size-adjust: 105.15%
}.__className_13c7d6 {font-family: '__Open_Sans_13c7d6', '__Open_Sans_Fallback_13c7d6';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Cinzel_9f174a';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/2fb380e60212dc49-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cinzel_9f174a';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/42f131834de1b4dc-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Cinzel_Fallback_9f174a';src: local("Times New Roman");ascent-override: 71.31%;descent-override: 27.18%;line-gap-override: 0.00%;size-adjust: 136.86%
}.__className_9f174a {font-family: '__Cinzel_9f174a', '__Cinzel_Fallback_9f174a';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b8e89a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b8e89a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b8e89a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b8e89a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b8e89a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/4f05ba3a6752a328-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_b8e89a';src: local("Arial");ascent-override: 85.79%;descent-override: 22.25%;line-gap-override: 0.00%;size-adjust: 112.83%
}.__className_b8e89a {font-family: '__Montserrat_b8e89a', '__Montserrat_Fallback_b8e89a';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Raleway_20b3bc';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/200388358b398524-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Raleway_20b3bc';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/34900c74a84112b6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Raleway_20b3bc';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/10dadb2e82d03733-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Raleway_20b3bc';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/5f2068c3133468f5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Raleway_20b3bc';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/9bf67a161a796382-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Raleway_Fallback_20b3bc';src: local("Arial");ascent-override: 90.51%;descent-override: 22.53%;line-gap-override: 0.00%;size-adjust: 103.86%
}.__className_20b3bc {font-family: '__Raleway_20b3bc', '__Raleway_Fallback_20b3bc';font-style: normal
}

/* arabic */
@font-face {
  font-family: '__Rubik_c9e260';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_c9e260';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_c9e260';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_c9e260';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_c9e260';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_c9e260';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/c22ccc5eb58b83e1-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Rubik_Fallback_c9e260';src: local("Arial");ascent-override: 89.06%;descent-override: 23.81%;line-gap-override: 0.00%;size-adjust: 104.98%
}.__className_c9e260 {font-family: '__Rubik_c9e260', '__Rubik_Fallback_c9e260';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Epilogue_ece44d';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/17e0992af308dbf5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Epilogue_ece44d';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/5e34f549e172cb28-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Epilogue_ece44d';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/8347d131139854e0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Epilogue_Fallback_ece44d';src: local("Arial");ascent-override: 71.15%;descent-override: 21.16%;line-gap-override: 0.00%;size-adjust: 111.04%
}.__className_ece44d {font-family: '__Epilogue_ece44d', '__Epilogue_Fallback_ece44d';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Oswald_937238';
  font-style: normal;
  font-weight: 200 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/c2958e922dd5a3df-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Oswald_937238';
  font-style: normal;
  font-weight: 200 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/08ff561b69b8d3e5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Oswald_937238';
  font-style: normal;
  font-weight: 200 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/75f831700aaea22d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Oswald_937238';
  font-style: normal;
  font-weight: 200 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/7f78a3adbb2adbe3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oswald_937238';
  font-style: normal;
  font-weight: 200 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/f8a669a999c483ca-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Oswald_Fallback_937238';src: local("Arial");ascent-override: 146.51%;descent-override: 35.49%;line-gap-override: 0.00%;size-adjust: 81.43%
}.__className_937238 {font-family: '__Oswald_937238', '__Oswald_Fallback_937238';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_c81559';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/bde16c1724335d95-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_c81559';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/8a9e72331fecd08b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_c81559';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/0610ebff456d6cfc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_c81559';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/e3b8d441242e07fb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_c81559';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/21ed5661b47f7f6d-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Fallback_c81559';src: local("Arial");ascent-override: 99.71%;descent-override: 34.82%;line-gap-override: 0.00%;size-adjust: 101.39%
}.__className_c81559 {font-family: '__Nunito_c81559', '__Nunito_Fallback_c81559';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Work_Sans_20db7e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/0822b6067e7c0dee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_20db7e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/67354d9f27664b22-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_20db7e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/6905431624c34d00-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Work_Sans_Fallback_20db7e';src: local("Arial");ascent-override: 83.09%;descent-override: 21.71%;line-gap-override: 0.00%;size-adjust: 111.93%
}.__className_20db7e {font-family: '__Work_Sans_20db7e', '__Work_Sans_Fallback_20db7e';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Cabin_fcc78b';
  font-style: normal;
  font-weight: 400 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/341f51d096ab384a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cabin_fcc78b';
  font-style: normal;
  font-weight: 400 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/7a70184745ebe6b5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cabin_fcc78b';
  font-style: normal;
  font-weight: 400 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/e8d2af9ac91a2b1e-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Cabin_Fallback_fcc78b';src: local("Arial");ascent-override: 101.94%;descent-override: 26.41%;line-gap-override: 0.00%;size-adjust: 94.66%
}.__className_fcc78b {font-family: '__Cabin_fcc78b', '__Cabin_Fallback_fcc78b';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Arimo_afe75e';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/54cebcf4880b1614-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Arimo_afe75e';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/6979ad0d760ef130-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Arimo_afe75e';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/bdd48e79561bcb5e-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Arimo_afe75e';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/cfbaade285b042b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Arimo_afe75e';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/4549bc8a45479062-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: '__Arimo_afe75e';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/9d75a3f01345607c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Arimo_afe75e';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/f6931192f105f318-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Arimo_afe75e';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/ce5ee29719474941-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Arimo_Fallback_afe75e';src: local("Arial");ascent-override: 90.53%;descent-override: 21.19%;line-gap-override: 3.27%;size-adjust: 100.00%
}.__className_afe75e {font-family: '__Arimo_afe75e', '__Arimo_Fallback_afe75e';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__EB_Garamond_4f382a';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/d7b29b398dd797a1-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__EB_Garamond_4f382a';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/5d4a6d6d18033392-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__EB_Garamond_4f382a';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/891631c764a307b2-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__EB_Garamond_4f382a';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/73b9b49ded63c2ac-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__EB_Garamond_4f382a';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/8b7ed269fbb6e772-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__EB_Garamond_4f382a';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/ef23c27b86d93109-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__EB_Garamond_4f382a';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/7aa35bcef8fce17b-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__EB_Garamond_Fallback_4f382a';src: local("Times New Roman");ascent-override: 106.26%;descent-override: 31.44%;line-gap-override: 0.00%;size-adjust: 94.77%
}.__className_4f382a {font-family: '__EB_Garamond_4f382a', '__EB_Garamond_Fallback_4f382a';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Faustina_e79aae';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/e97a34be86426e38-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Faustina_e79aae';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/dfacfb498c81830f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Faustina_e79aae';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/2d489a65560656c1-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Faustina_Fallback_e79aae';src: local("Times New Roman");ascent-override: 101.86%;descent-override: 21.19%;line-gap-override: 0.00%;size-adjust: 102.40%
}.__className_e79aae {font-family: '__Faustina_e79aae', '__Faustina_Fallback_e79aae';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Karla_56aedc';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_56aedc';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/ba5f7ed75552623e-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Karla_Fallback_56aedc';src: local("Arial");ascent-override: 89.55%;descent-override: 24.61%;line-gap-override: 0.00%;size-adjust: 102.40%
}.__className_56aedc {font-family: '__Karla_56aedc', '__Karla_Fallback_56aedc';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Lora_675587';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/8976b37f8037a4c0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Lora_675587';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/ada9171bd6839183-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* math */
@font-face {
  font-family: '__Lora_675587';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/21633f890e359fee-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Lora_675587';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/9c526da3fdc4ac93-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Lora_675587';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/d364c42434837330-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Lora_675587';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/293d9d85a89f760b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lora_675587';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/3a8dc5763a8ae4b2-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lora_Fallback_675587';src: local("Times New Roman");ascent-override: 87.33%;descent-override: 23.78%;line-gap-override: 0.00%;size-adjust: 115.20%
}.__className_675587 {font-family: '__Lora_675587', '__Lora_Fallback_675587';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Sora_79b8cd';
  font-style: normal;
  font-weight: 100 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/97a9b9c5d2a0c527-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sora_79b8cd';
  font-style: normal;
  font-weight: 100 800;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/a2117d63e64fe351-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sora_Fallback_79b8cd';src: local("Arial");ascent-override: 85.29%;descent-override: 25.50%;line-gap-override: 0.00%;size-adjust: 113.73%
}.__className_79b8cd {font-family: '__Sora_79b8cd', '__Sora_Fallback_79b8cd';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Urbanist_d06f05';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/8cdee4d3ed444abc-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Urbanist_d06f05';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://edge.churchdesk.com/_next/static/media/01af0fc7b4278e65-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Urbanist_Fallback_d06f05';src: local("Arial");ascent-override: 95.93%;descent-override: 25.24%;line-gap-override: 0.00%;size-adjust: 99.04%
}.__className_d06f05 {font-family: '__Urbanist_d06f05', '__Urbanist_Fallback_d06f05';font-style: normal
}

